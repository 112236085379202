import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "./button"
import content from "../../data/content.json"
import IconShape from "./icons"

const borderMap = {
  Simple: "rounded-xl",
  Smart: "rounded-xl",
  Playful: "rounded-xl",
  Elegant: "rounded-none",
  Hacker: "rounded-none",
  Bold: "rounded-xl",
  Modern: "rounded-none",
}

const colorMap = {
  Indigo: "indigo-500",
  Blue: "blue-500",
  Lime: "lime-500",
  Yellow: "yellow-300",
  Orange: "yellow-600",
  Pink: "pink-500",
  Purple: "purple-500",
  Red: "red-500",
  Green: "green-500",
  Black: "black",
}

const ImageCard = ({ block, theme, blocksLength }) => {
  const style = theme.style
  const headingSize = blocksLength > 3 && "sm:text-2xl"

  return (
    <div id="item" className={` overflow-hidden`}>
      <div className=" overflow-hidden">
        <GatsbyImage
          image={block.Image?.localFiles[0].childImageSharp.gatsbyImageData}
          alt={block.Asset_title}
          style={{height: 250}}
        />
      </div>
      <div
        id="card_content"
        className="py-4 lg:py-6 space-y-4 flex flex-wrap flex-col content-between justify-start"
      >
        <h3 id="title" className={`max-w-prose tracking-tight ${headingSize}`}>
          {block.Asset_title}
        </h3>
        <p className="max-w-prose antialiased line-clamp-4">
          {block.Description_1}
        </p>
      </div>
    </div>
  )
}

const Icon = ({ block, theme, blocksLength }) => {

  const palette = theme.palette
  const headingSize = blocksLength > 3 && "sm:text-2xl"

  return (
    <div id="item" className="space-y-1">
      <IconShape className={`h-10 w-10 text-${colorMap[palette]}`} icon={block.Symbol} fill="none" />
      <h3 id="title" className={headingSize}>{block.Asset_title}</h3>
      <p>{block.Description_1}</p>
    </div>
  )
}

const Logo = ({ block }) => {
  return (
    <div id="image" className="h-20 w-20 mb-20 justify-self-center">
        <GatsbyImage
          image={block.Image?.localFiles[0].childImageSharp.gatsbyImageData}
          alt={block.Asset_title}
        />
      </div>
  )
}

const SideImage = ({ block, index, theme }) => {
  let zigZag =
    index % 2 === 0 ? "sm:flex-row" : "sm:flex-row-reverse sm:space-x-reverse"

  let blockCaption = block.Asset_title ? block.Asset_title : `Step ${index + 1}`

  const palette = theme.palette

  return (
    <div
      id="item"
      className={`flex flex-col ${zigZag} space-y-4 sm:space-y-0 sm:space-x-16 text-left`}
    >
      <div id="image" className="sm:w-1/2 overflow-hidden">
        <GatsbyImage
          image={block.Image?.localFiles[0].childImageSharp.gatsbyImageData}
          alt={block.Asset_title}
        />
      </div>
      <div id="header" className="sm:w-1/2 md:pr-16">
        <p className={`text-${colorMap[palette]} uppercase font-bold`}>
          {blockCaption}
        </p>
        <h3 className="pb-4">{block.Description_1}</h3>
        <p>{block.Description_2}</p>
        <br />
        <p>{block.Description_3}</p>
      </div>
    </div>
  )
}

const Plan = ({ block, openModal, theme, blocksLength, palette }) => {
  const style = theme.style
  const headingSize = blocksLength > 3 && "md:text-2xl"
console.log(block)
  return (
    <div
      id="item"
      className={`${borderMap[style]} card flex flex-wrap space-y-6 p-4 lg:p-6 content-between`}
    >
      <div className="space-y-3">
        <h3 id="title" className={headingSize}>{block.Asset_title}</h3>
        <p>{block.Description_2}</p>
      </div>
      <div className="h-16">
        <h4 id="amount">{block.Description_1}</h4>
        <p className="text-sm">{block.Additional_info}</p>
      </div>
      <Button
        onClick={openModal}
        content={content}
        type="outline"
        className="w-full"
      >
        {content.action.form.text}
      </Button>
    </div>
  )
}

const Testimonial = ({ block }) => {
  return (
    <div id="text" className="justify-items-center">
      <h4 className="leading-relaxed lg:px-48 pb-16">{block.Description_1}</h4>
      <p className="font-bold text-white">{block.Asset_title}</p>
      <p className="text-white">{block.Additional_info}</p>
    </div>
  )
}

const Cta = ({ block, openModal }) => {
  return (
    <div id="action" className="space-y-8 sm:space-y-16">
      <h2 className="leading-relaxed">{block.Description_1}</h2>
      <Button onClick={openModal} content={content} type="secondary">
        {content.action.form.text}
      </Button>
    </div>
  )
}

const Subscribe = ({ block }) => {
  return (
    <div id="content" className="justify-items-center text-center ">
      <form className="space-x-4 space-y-4 sm:space-y-0">
        <input
          type="text"
          className="textfield-rounded focus:outline-none"
          maxlength="200"
          name="Email-Address"
          data-name="Email Address"
          placeholder="Email Address"
          id="Email-Address"
        ></input>
        <input type="submit" className="btn btn-blue" value="Subscribe"></input>
      </form>
    </div>
  )
}

export { ImageCard, SideImage, Plan, Testimonial, Icon, Cta, Subscribe, Logo }
